<template>
  <div class="login-content">
    <div class="flex">
      <Input style="width: 200px" v-model:value="phone" />
      <Input
        type="password"
        style="width: 200px;margin-left:20px;"
        v-model:value="password"
      />
      <Button style="margin-left:20px;" @click="handleLogin">登录</Button>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { Input, Button } from "ant-design-vue";
import { useStore } from "vuex";
import $await from "@/utils/await";

export default {
  name: "login",
  components: { Input, Button },

  setup() {
    const store = useStore();

    const phone = ref(""); // 账号
    const password = ref(""); // 密码

    // 登录
    const handleLogin = async () => {
      await $await(
        store.dispatch("user/_login", {
          phone: phone.value,
          password: password.value
        })
      );
    };

    return { handleLogin, phone, password };
  }
};
</script>

<style lang="less">
.login-content {
  .flex {
    margin-top: 100px;
    display: flex;
    justify-content: center;
  }
}
</style>
